import axios from '@/libs/axios'

export default class DeliveryRoutes {

    getAssignedRoutes(query) {
        return axios.get(`/v2/RutasEntregas/GetAssignedRoutes`, { params: query })
    }

    findByDoctoPvId(doctoPvId) {
        return axios.get(`/v2/RutasEntregas/FindByDoctoPvId/${doctoPvId}`)
    }

    getAll(query) {
        return axios.get(`/v2/RutasEntregas/GetAll`, { params: query })
    }

    getAllByFilters (query) {
        return axios.get(`/v2/RutasEntregas/GetAllByFilters`, { params: query })
        
    }

    getAllByUserId(idUsuario = null, status, almacen_Id = 0, cantidad = 0) {
        return axios.get(`/v2/RutasEntregas/GetAllByUsers`, { params: {idUsuario: idUsuario, status: status, almacen_Id: almacen_Id, cantidad: cantidad} })
    }

    store(payload) {
        return axios.post(`/v2/RutasEntregas/Store`, payload)        
    }

    update(payload) {
        return axios.post(`/v2/RutasEntregas/Update?isPending=${payload.isPending}`, payload)
    }

    output(payload) {
        return axios.post(`/v2/RutasEntregas/Output`, payload)
    }

    deliveryDocument (doctoPvId) {
        return axios.get(`/v2/RutasEntregas/DeliveryDocument/${doctoPvId}`,{ responseType: 'arraybuffer' })
    }

    getReturns(doctoPvId) {
        return axios.get(`/v2/RutasEntregas/GetReturns/${doctoPvId}`)
    }

    getHistorial(doctoPvId) {
        return axios.get(`/v2/RutasEntregas/GetHistorial/${doctoPvId}`)
    }

    reOpenInvoice(rutaEntregaId) {
        return axios.get(`/v2/RutasEntregas/ReOpenInvoice/${rutaEntregaId}`)
    }

    canRouting(doctoPvId, tipoDocto, details) {
        return axios.post(`/v2/RutasEntregas/CanRouting/${doctoPvId}/${tipoDocto}`, details)
    }

    closingInvoice(payload) {
        return axios.get(`/v2/RutasEntregas/ClosingInvoice/${payload.id}/${payload.isPending}`)
    }

    getTransfersWarehouses(query) {
        return axios.get(`/v2/RutasEntregas/GetTransfersWarehouses/${query.inicio}/${query.fin}/${query.es_bitacora}`)
    }

    exportTransferWarehouses(query) {
        return axios.get(`/v2/RutasEntregas/ExportTransfersWarehouses/${query.inicio}/${query.fin}/${query.es_bitacora}`,
            {
                responseType: 'blob' 
            }
        )
    }

    storeComments(payload) {
        return axios.post(`/v2/RutasEntregas/StoreComments`, payload)
    }

    getCommentsByDoctoPvId(doctoPvId) {
        return axios.get(`/v2/RutasEntregas/GetCommentsByDoctoPvId/${doctoPvId}`)
    }

    getDetailsVsOldSystem(query) {
        return axios.get(`/v2/RutasEntregas/GetDetailsVsOldSystem`, { params: query })
    }

    getQuantitiesByStatus(almacen_Id, isPrinted = false) {
        return axios.get(`/v2/RutasEntregas/GetQuantities/${almacen_Id}`, {params: {
            noImpresos: isPrinted,
        }})
    }

    setPrintDocument(routingId) {
        return axios.get(`/v2/RutasEntregas/SetPrintDocument/${routingId}`)
    }

    setCustomersPassBy(payload) {
        return axios.post(`/v2/RutasEntregas/CustomersPassBy`, payload)
    }

    markAsReceivedDocument(routingId) {
        return axios.get(`/v2/RutasEntregas/MarkAsReceivedDocument/${routingId}`)
    }

    markAsInvoiceDeliveredToDriver(routingId) {
        return axios.get(`/v2/RutasEntregas/MarkAsInvoiceDeliveredToDriver/${routingId}`)
    }

    getRoutingHistorial(routingId) {
        return axios.get(`/v2/RutasEntregas/GetRoutingHistorial/${routingId}`)
    }
}
